export enum ActivityType {
    YAZHOULONG = 'yazhoulong',
    puladuo_baoke_jiudian_20240804 = 'puladuo_baoke_jiudian_20240804',
    puladuo_baoke_jiayou_20240804 = 'puladuo_baoke_jiayou_20240804',
    puladuo_qianke_20240804 = 'puladuo_qianke_20240804',
    avalon_20240804_test='avalon_20240804_test',
    test_20240808='test_20240808',
    alipay_spi_1103='alipay_spi_1103',
    // 试驾
    alipay_mini_program_1103='alipay_mini_program_1103',
    // 权益
    alipay_mini_program_quanyi_1120='alipay_mini_program_quanyi_1120',
    // 会员
    alipay_mini_program_member_1120='job_member_12_06',

    job_benz_11_24='job_benz_11_24',
}

export const ActivityTypeMap = {
    [ActivityType.job_benz_11_24]: {
        text: '奔驰小程序',
    },
    [ActivityType.alipay_mini_program_1103]: {
        text: '丰田小程序-试驾',
    },
    [ActivityType.alipay_mini_program_quanyi_1120]: {
        text: '丰田小程序-权益',
    },
    [ActivityType.alipay_mini_program_member_1120]: {
        text: '丰田小程序-会员',
    },
    [ActivityType.alipay_spi_1103]: {
        text: '车生活落地页',
    },
    [ActivityType.YAZHOULONG]: {
        text: '亚洲龙-保客',
    },
    [ActivityType.puladuo_baoke_jiudian_20240804]: {
        text: '普拉多-保客-酒店权益',
    },
    [ActivityType.puladuo_baoke_jiayou_20240804]: {
        text: '普拉多-保客-加油权益',
    },
    [ActivityType.puladuo_qianke_20240804]: {
        text: '普拉多-潜客',
    },
    [ActivityType.avalon_20240804_test]: {
        text: '亚洲龙-潜客',
    },
    [ActivityType.test_20240808]: {
        text: '内部测试活动',
    },
    
}

export const MemberTypeMap = {
    1: {
        text: '新客',
    },
    0: {
        text: '老客',
    },
};

export enum SendStatus {
    All = 'all',
    Send = 1,
    unSend = 0,
    Fail = -1,
};

export const SendStatusName = {
    [SendStatus.All]: {
        title: '全部',
        text: '全部',
        color: 'red',
    },
    [SendStatus.Fail]: {
        title: '推送失败',
        text: '推送失败',
        color: 'red'
    },
    [SendStatus.unSend]:  {
        title: '未推送',
        text: '未推送',
        color: 'gray'
    },
    [SendStatus.Send]:  {
        title: '推送成功',
        text: '推送成功',
        color: 'green'
    },
}

export const sendMsgWhite = [ActivityType.test_20240808, ActivityType.avalon_20240804_test];

