import React from "react";
import dayjs from 'dayjs';
import { DatePicker, Space, Table, Select,Badge, Alert, Typography, message, Image, Button } from 'antd';
import { useApiSelector } from 'src/hooks'
import { useRequest, dispatchAsync } from 'friday-async'
import { ActivityType, SendStatus, SendStatusName, sendMsgWhite, ActivityTypeMap } from './constants';
import { useExcelService } from './useExcelService';
import config from '../../config'

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD'
const Analysis = () => {
    const apis = useApiSelector();
    const { onDownloadLeads } = useExcelService();
    const [loading, setLoading] = React.useState(false)

    const [filterInfo, setFilterInfo] = React.useState({
        startTime: dayjs(),
        endTime: dayjs(),
        status: SendStatus.All,
        jobName: ActivityType.alipay_spi_1103,
    })

    
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<any[]>([]);
    const selectRowsRef = React.useRef<any[]>([]);

    const requestBody = React.useMemo(() => {
        return {
            ...filterInfo,
            startTime: dayjs(filterInfo.startTime).format(dateFormat),
            endTime: dayjs(filterInfo.endTime).format(dateFormat)
        }
    }, [filterInfo])

    const { dataArray, isValidating, revalidate } = useRequest(apis.analysis.getLeads(requestBody))

    const onUpdateFilter = (nextValueField) => {
        
        setFilterInfo({
            ...filterInfo,
            ...nextValueField
        })
        onSelectRowKeys([])
    }
    const batchSendMsg = (sendInfos?: any[]) => {
        let nextSendInfos = sendInfos;

        if (!nextSendInfos) {
            nextSendInfos = selectRowsRef.current;
        }

        const filterUnSendMsg = nextSendInfos?.filter(i => i.isSendShotMsg !== SendStatus.Send)

        if (!filterUnSendMsg.length) {
            return message.error('没有未发送短信的线索')
        }

        if (filterUnSendMsg.length > 10) {
            return message.error('请勿一次发送大于10条短信')
        }
        
        const promises = nextSendInfos.map(i => {
            return dispatchAsync(apis.analysis.sendShotMsg({
                tempId: '2234291',
                userId: i._id, 
                phone: i.tel, 
                jobName: i.jobName
            }))
        })
        setLoading(true);
        Promise.all(promises).then(() => {
            message.success('短信发送成功');
            setLoading(false);
            revalidate();
            onSelectRowKeys([])
        });
    };

    const deleteLead = async (_id) => {
        await dispatchAsync(apis.analysis.delete({
            _id, 
        }))

        revalidate()
    }

    const onDownloadLeadsHandel = () => {
        const downloadData = selectRowsRef.current.map(i => {
            const { seriesId, } = i.leadInfo || {};
            return {
                ...i,
                seriesId,
                assest: i.assest?.[0]
            }
        });

        onDownloadLeads(
            `${filterInfo.startTime.format('YYYYMMDD')}-${filterInfo.endTime.format('YYYYMMDD')}-${filterInfo.jobName}`, 
            downloadData
        )
    }
    const onSelectRowKeys = (rowIds) => {
        const rows = dataArray.filter(i => rowIds.indexOf(i._id) > -1)
        selectRowsRef.current = rows;
        setSelectedRowKeys(rowIds)
    }

    const rowSelection = {
        selectedRowKeys,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
        ],
        onChange: (rowIds) => onSelectRowKeys(rowIds),
    };

    const countUnsendMsg = selectRowsRef.current.filter(i => i.isSendShotMsg !== SendStatus.Send)?.length

    console.log(selectRowsRef.current, 'selectRowsRef')
    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Space>
                线索时间：
                <RangePicker
                    format={'YYYY-MM-DD'}
                    value={[filterInfo.startTime, filterInfo.endTime]}
                    onChange={(values) => {
                        onUpdateFilter({
                            startTime: values![0],
                            endTime: values![1],
                        })
                    }}
                />
                线索推送状态
                <Select
                    value={filterInfo.status}
                    style={{ width: 120 }}
                    onChange={(val) => {
                        onUpdateFilter({status: val})
                    }}
                    dropdownStyle={{width: 200}}
                    options={[
                        { value: SendStatus.All, label:  SendStatusName[SendStatus.All].title},
                        { value: SendStatus.Send, label:  SendStatusName[SendStatus.Send].title},
                        { value: SendStatus.unSend, label: SendStatusName[SendStatus.unSend].title },
                        { value: SendStatus.Fail, label: SendStatusName[SendStatus.Fail].title },
                    ]}
                />

                当前活动
                <Select
                    value={filterInfo.jobName}
                    style={{ width: 200 }}
                    onChange={(val) => {    
                        onUpdateFilter({jobName: val})
                    }}
                    dropdownStyle={{width: 300}}
                    options={[
                        { value: ActivityType.alipay_spi_1103, label: ActivityTypeMap[ActivityType.alipay_spi_1103].text},
                        { value: ActivityType.job_benz_11_24, label: ActivityTypeMap[ActivityType.job_benz_11_24].text},
                        { value: ActivityType.alipay_mini_program_1103, label: ActivityTypeMap[ActivityType.alipay_mini_program_1103].text},
                        { value: ActivityType.alipay_mini_program_quanyi_1120, label: ActivityTypeMap[ActivityType.alipay_mini_program_quanyi_1120].text},
                        { value: ActivityType.alipay_mini_program_member_1120, label: ActivityTypeMap[ActivityType.alipay_mini_program_member_1120].text},
                        { value: ActivityType.puladuo_baoke_jiayou_20240804, label: ActivityTypeMap[ActivityType.puladuo_baoke_jiayou_20240804].text},
                        { value: ActivityType.puladuo_baoke_jiudian_20240804, label:  ActivityTypeMap[ActivityType.puladuo_baoke_jiudian_20240804].text},
                        { value: ActivityType.puladuo_qianke_20240804, label:  ActivityTypeMap[ActivityType.puladuo_qianke_20240804].text},
                        { value: ActivityType.YAZHOULONG, label:  ActivityTypeMap[ActivityType.YAZHOULONG].text},
                        { value: ActivityType.avalon_20240804_test, label:  ActivityTypeMap[ActivityType.avalon_20240804_test].text },
                        { value: ActivityType.test_20240808, label:  ActivityTypeMap[ActivityType.test_20240808].text },
                    ]}
                />
                 <Typography.Text>共{dataArray.length}条</Typography.Text>
            </Space>

            <Space direction="vertical">
                {selectedRowKeys.length ? (
                    <Space>
                        <Typography.Text>{`当前选择了 ${selectedRowKeys.length}条：`}</Typography.Text>
                        {sendMsgWhite.indexOf(filterInfo.jobName) > -1 ? (
                            <Button type='primary' loading={loading} onClick={() => batchSendMsg()} ghost>批量发送短信（未发送{countUnsendMsg}）</Button>
                        ): null}
                        <Button type='primary'  onClick={() => onDownloadLeadsHandel()} ghost>下载报表</Button>
                    </Space>
                ): null}   
            </Space>

            <Table
                rowKey='_id'
                loading={isValidating}
                scroll={{ x: 2000}}
                rowSelection={rowSelection}
                columns={[
                    {
                        title: 'id',
                        dataIndex: '_id',
                        key: '_id',
                        width: 160,
                    },
                    {
                        title: '手机号',
                        dataIndex: 'tel',
                        key: 'tel',
                        width: 140,
                    },
                    {
                        title: '活动类型',
                        dataIndex: 'jobName',
                        key: 'jobName',
                        width: 140,
                        render(jobName) { 
                            return ActivityTypeMap[jobName]?.text || jobName;
                        }
                    },
                    {
                        title: '车系',
                        dataIndex: 'seriesId',
                        key: 'seriesId',
                        width: 60,
                        render(_, record) { 
                            return record.leadInfo?.seriesId;
                        }
                    },
                    {
                        title: '会员类型',
                        dataIndex: 'memberType',
                        key: 'memberType',
                        width: 120,
                        render(_, record) { 
                            if (record?.isNew === undefined) {
                                return '--';
                            }
                            return record?.isNew === 1 ? '新客' : '老客';
                        }
                    },
                    {
                        title: '权益成功数',
                        dataIndex: 'receiveSuccessLenth',
                        key: 'receiveSuccessLenth',
                        width: 110,
                    },
                    {
                        title: '权益成功ID',
                        dataIndex: 'receiveSuccessCouponId',
                        key: 'receiveSuccessCouponId',
                        width: 120,
                    },
                    {
                        title: '权益失败数',
                        dataIndex: 'receiveFailLenth',
                        key: 'receiveFailLenth',
                        width:120,
                    },
                    {
                        title: '权益失败明细',
                        dataIndex: 'receiveFailComment',
                        key: 'receiveFailComment',
                        width: 160,
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'createTime',
                        key: 'createTime',
                        width: 120,
                    },
                    {
                        title: '一丰回传',
                        dataIndex: 'sendStatus',
                        key: 'sendStatus',
                        render(val) {
                            return  <Badge text={SendStatusName[val]?.title} color={SendStatusName[val]?.color} /> 
                        }
                    },
                    {
                        title: '发送短信',
                        dataIndex: 'isSendShotMsg',
                        key: 'isSendShotMsg',
                        render(val) {
                            return  <Badge text={SendStatusName[val]?.title} color={SendStatusName[val]?.color} /> 
                        }
                    },
                    {
                        title: '试驾协议',
                        dataIndex: 'img',
                        key: 'img',
                        render(_, record) {
                            const url =  record.assest?.[0] ? config.imgHost + record.assest?.[0] : undefined;
                            return url ? 
                                <Image
                                    width={50}
                                    src={url}
                                /> : '未上传'
                        }
                    },
                    {
                        title: 'ocpc回传',
                        dataIndex: 'conversionStatus',
                        key: 'conversionStatus',
                        render(val) {
                            return  <Badge text={SendStatusName[val]?.title} color={SendStatusName[val]?.color} /> 
                        }
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        key: 'oper',
                        render(val, record) {
                            const { isSendShotMsg, jobName,_id } = record
                            const unDisabled = sendMsgWhite.indexOf(jobName) > -1 && isSendShotMsg !== SendStatus.Send;
                            return  (
                                <Space>
                                    <Typography.Link 
                                        onClick={() => batchSendMsg([record])}
                                        disabled={!unDisabled} 
                                    >发送短信</Typography.Link>
                                    <Typography.Link 
                                        onClick={() => deleteLead(_id)}
                                    >删除</Typography.Link>
                                </Space>
                            ) 
                        }
                    },
                ]}
                dataSource={dataArray}
            />
        </Space>
    )
}

export default Analysis;