import { createGetApi, createPostApi } from 'friday-async';
import config from 'src/config'
import { ActivityType } from './constants'
enum SendStatus {
  Send = 1,
  unSend = 0,
  Fail = -1,
  All = '',
}



class Apis {
  public getLeads = createGetApi<{
    startTime: string;
    endTime: string;
    status: SendStatus;
    jobName: ActivityType;
  }>({
    url: '/lead/get',
    baseURL: config.host + '/api/v2/activity',
  });

  sendShotMsg = createPostApi<{
    tempId: string;
    userId: string, 
    phone: string, 
    jobName: string
  }>({
    url: '/cloud/sendMsg',
    baseURL: config.host + '/api/v2',
  })

  delete = createGetApi<{
    _id: string;
  }>({
    url: '/lead/delete',
    baseURL: config.host + '/api/v2/activity',
  })
}

export default Apis;